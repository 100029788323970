
  import {Component, Vue} from 'vue-property-decorator';
  import {tokenModule} from '@/stores/authentication/Authentication';
  import {loginModule} from '@/stores/authentication/Account';

  @Component
  export default class Expire extends Vue {
    // method
    toSignIn(): void {
      this.$router.push({name: 'signin'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    // life cyclehook
    public created(): void {
      tokenModule.clear();
      loginModule.clear();
    }
  }
